import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import { fetchPrices } from "~/lib/astrolescent";
import { IGNITION_COMPONENT_ADDRESS, IGNITION_PRICES_KV, IGNITON_NONVOLATILE_VAULT, IGNITON_VOLATILE_VAULT } from "~/lib/ignition";
import { SelectPair } from "~/lib/models/pair";
import { SelectToken } from "~/lib/models/tokens";
import { DEX_COMPONENT_ADDRESS, RADIX_GATEWAY_URL, getQuoteToken } from "~/lib/util";

console.log('RADIX_GATEWAY_URL', RADIX_GATEWAY_URL);

export const useGatewayApi = GatewayApiClient.initialize({
	basePath: RADIX_GATEWAY_URL,
	applicationName: 'DefiPlaza'
});

// export async function getTokenDetails(tokenAddress: string) {
// 	const { state } = useGatewayApi;

// 	const componentDetails = await state.getEntityDetailsVaultAggregated(componentAddress);
// }

export async function fetchTokensFromWallet(address: string) {
	const { state } = useGatewayApi;

	let resources = {};
	let addresses = [];
	let firstRun = true;
	let nextCursor;
	let stateVersion;

	while (firstRun || !!nextCursor) {
		firstRun = false;

		let query: StateEntityFungiblesPageRequest = {
			address
		}

		if (nextCursor) {
			query = {
				address,
				cursor: nextCursor,
				at_ledger_state: {
					state_version: stateVersion
				}
			}
		}

		const walletState = await state.innerClient.entityFungiblesPage({
			stateEntityFungiblesPageRequest: query
		});

		nextCursor = walletState.next_cursor;
		stateVersion = walletState.ledger_state.state_version;


		for (let item of walletState.items) {
			if (parseFloat(item.amount) == 0) {
				continue;
			}

			addresses.push(item.resource_address);

			resources[item.resource_address] = {
				amount: item.amount
			};
		}
	}

	const details = await fetchTokensDetails(addresses);

	const tokens = [];

	for (let token of details) {
		tokens.push({
			...token,
			amount: resources[token.address].amount
		})
	}

	return tokens;
}


// export async function fetchTokensFromWallet(address: string) {
// 	const { state } = useGatewayApi;

// 	const walletState = await state.getEntityDetailsVaultAggregated(address);

// 	let addresses = [];
// 	let resources = {};

// 	for (let item of walletState.fungible_resources.items) {
// 		if (parseFloat(item.vaults.items[0].amount) == 0) {
// 			continue;
// 		}

// 		addresses.push(item.resource_address);

// 		resources[item.resource_address] = {
// 			amount: item.vaults.items[0].amount
// 		};
// 	}

// 	const details = await fetchTokensDetails(addresses);

// 	const tokens = [];

// 	for (let token of details) {
// 		tokens.push({
// 			...token,
// 			amount: resources[token.address].amount
// 		})
// 	}

// 	return tokens;
// }

export async function fetchTokensDetails(addresses: string[], extendedData?: boolean) {
	const { state } = useGatewayApi;

	const tokenDetails = await state.getEntityDetailsVaultAggregated(addresses);

	let tokens = [];

	for (let lpTokenDetail of tokenDetails) {
		let symbol = 'UNKNOWN', name = '', iconUrl = '', infoUrl = '', description = '';

		for (let meta of lpTokenDetail.metadata.items) {
			if (meta.key == 'symbol') {
				// @ts-ignore
				symbol = meta.value.typed.value;
			}

			if (meta.key == 'name') {
				// @ts-ignore
				name = meta.value.typed.value;
			}

			if (meta.key == 'icon_url') {
				// @ts-ignore
				iconUrl = meta.value.typed.value;
			}

			if (meta.key == 'info_url') {
				// @ts-ignore
				infoUrl = meta.value.typed.value;
			}

			if (meta.key == 'description') {
				// @ts-ignore
				description = meta.value.typed.value;
			}
		}

		const token: SelectToken = {
			symbol,
			name,
			iconUrl,
			description,
			infoUrl,
			address: lpTokenDetail.address,
			totalSupply: lpTokenDetail.details?.total_supply,
			divisibility: lpTokenDetail.details?.divisibility
		}

		tokens.push(token)
	}

	return tokens;
}

export async function fetchTokenDetailsWithOwner(addresses: string[], extendedData?: boolean) {
	const { state } = useGatewayApi;

	const tokenDetails = await state.getEntityDetailsVaultAggregated(addresses);

	let tokens = [];

	for (let lpTokenDetail of tokenDetails) {
		let symbol = 'UNKNOWN', name = '', iconUrl = '', infoUrl = '', description = '', stakeComponent;
		let locked = [];

		for (let meta of lpTokenDetail.metadata.items) {
			if (meta.key == 'symbol') {
				// @ts-ignore
				symbol = meta.value.typed.value;
			}

			if (meta.key == 'name') {
				// @ts-ignore
				name = meta.value.typed.value;
			}

			if (meta.key == 'icon_url') {
				// @ts-ignore
				iconUrl = meta.value.typed.value;
			}

			if (meta.key == 'info_url') {
				// @ts-ignore
				infoUrl = meta.value.typed.value;
			}

			if (meta.key == 'description') {
				// @ts-ignore
				description = meta.value.typed.value;
			}

			if (meta.key == 'stake_component') {
				// @ts-ignore
				stakeComponent = meta.value.typed.value;
			}

			if (meta.key == 'social_urls') {
				// @ts-ignore
				// console.log(meta.value.typed.values);
			}

			console.log(meta)

			if (meta.is_locked) {
				locked.push(meta.key)
			}
		}

		const ownerField = lpTokenDetail.details?.role_assignments.owner.rule.access_rule?.proof_rule.requirement;
		let ownerToken;

		// There are tokens without owners
		// so check before we start looking for it.
		if (ownerField) {
			if (ownerField.type == 'Resource') {
				ownerToken = {
					address: ownerField.resource,
					localId: null
				}
			} else {
				ownerToken = {
					address: ownerField.non_fungible.resource_address,
					localId: ownerField.non_fungible.local_id.simple_rep
				}
			}
		}

		const token: SelectToken = {
			symbol,
			name,
			iconUrl,
			description,
			infoUrl,
			address: lpTokenDetail.address,
			totalSupply: lpTokenDetail.details?.total_supply,
			divisibility: lpTokenDetail.details?.divisibility,
			stakeComponent,
			owner: ownerToken,
			locked
		}

		tokens.push(token)
	}

	return tokens;
}

export async function fetchTokenMetaData(address: string) {
	const { state } = useGatewayApi;

	const details = await state.getEntityDetailsVaultAggregated(address);


	let metas = {} as { [key: string]: { value: any, locked: boolean} };

	for (let meta of details.metadata.items) {
		let value = meta.value.typed.value;

		if (meta.value.typed.values) {
			value = meta.value.typed.values;
		} 

		metas[meta.key] = {
			value,
			locked: meta.is_locked
		}

	}

	return metas;
}

export async function fetchCreatedPairsFromEvents() {
	const { state, stream } = useGatewayApi;

	const result = await stream.innerClient.streamTransactions({
		streamTransactionsRequest: {
			"limit_per_page": 30,
			"kind_filter": "All",
			"affected_global_entities_filter": [
				DEX_COMPONENT_ADDRESS
			],
			"opt_ins": {
				"receipt_events": true
			}
		}
	});

	let pairs = [];

	for (let item of result.items) {
		let baseToken, component, p0, config, pairState;

		for (let event of item.receipt?.events) {

			if (event.name !== 'PairCreated') {
				continue;
			}

			// grab details from event
			for (let field of event.data.fields) {
				if (field.type_name == 'GlobalPlazaPair') {
					component = field.value;
				}

				if (field.field_name == 'base_token') {
					baseToken = field.value;
				}
			}

			// Fetch base token details
			const [tokenDetails] = await fetchTokensDetails([baseToken]);

			// Fetch pool details
			const componentDetails = await state.getEntityDetailsVaultAggregated(component);

			let basePoolAddress, quotePoolAddress;

			for (let field of componentDetails.details.state.fields) {

				if (field.field_name == 'base_pool') {
					basePoolAddress = field.value;
				}

				if (field.field_name == 'quote_pool') {
					quotePoolAddress = field.value;
				}

				if (field.field_name == 'config') {
					config = {
						k_in: 0,
						k_out: 0,
						fee: 0,
						decay_factor: 0
					};

					for (let configField of field.fields) {
						config[configField.field_name as keyof typeof config] = configField.value;
					}
				}

				if (field.field_name == 'state') {
					pairState = {
						p0: 0,
						shortage: 'Equilibrium',
						target_ratio: 0,
						last_outgoing: 0,
						last_out_spot: 0
					};

					for (let stateField of field.fields) {
						if (stateField.kind == 'Enum') {
							pairState[stateField.field_name as keyof typeof state] = stateField.variant_name;
						}
						else {
							pairState[stateField.field_name as keyof typeof state] = stateField.value;
						}
					}
				}
			}

			const poolDetails = await state.getEntityDetailsVaultAggregated([basePoolAddress, quotePoolAddress]);

			let basePool = {
				address: basePoolAddress,
				lpToken: {
					symbol: '',
					name: '',
					icon_url: '',
					address: '',
					totalSupply: '0'
				},
				vaults: [{
					address: '',
					amount: 0
				}, {
					address: '',
					amount: 0
				}]
			};

			let quotePool = {
				address: quotePoolAddress,
				lpToken: {
					symbol: '',
					name: '',
					icon_url: '',
					address: '',
					totalSupply: '0'

				},
				vaults: [{
					address: '',
					amount: 0
				}, {
					address: '',
					amount: 0
				}]
			};

			for (let poolDetail of poolDetails) {

				let isBase = poolDetail.address === basePool.address ? true : false;
				let pool = isBase ? basePool : quotePool;

				for (let resource of poolDetail.fungible_resources.items) {
					if (resource.resource_address == tokenDetails.address) {
						const index = isBase ? 0 : 1;

						pool.vaults[index] = {
							address: resource.resource_address,
							amount: parseFloat(resource.vaults.items[0].amount)
						}
					} else {
						const index = isBase ? 1 : 0;

						pool.vaults[index] = {
							address: resource.resource_address,
							amount: parseFloat(resource.vaults.items[0].amount)
						}
					}
				}

				// Fetch lpToken address
				for (let meta of poolDetail.metadata.items) {
					if (meta.key == 'pool_unit') {
						pool.lpToken.address = meta.value.typed.value;
					}
				}
			}

			const lpTokenDetails = await state.getEntityDetailsVaultAggregated([basePool.lpToken.address, quotePool.lpToken.address]);

			for (let lpTokenDetail of lpTokenDetails) {
				let pool = lpTokenDetail.address === basePool.lpToken.address ? basePool : quotePool;

				let symbol, name, icon_url;

				for (let meta of lpTokenDetail.metadata.items) {
					if (meta.key == 'symbol') {
						symbol = meta.value.typed.value;
					}

					if (meta.key == 'name') {
						name = meta.value.typed.value;
					}

					if (meta.key == 'icon_url') {
						icon_url = meta.value.typed.value;
					}
				}

				pool.lpToken.totalSupply = lpTokenDetail.details.total_supply;
				pool.lpToken.name = name;
				pool.lpToken.symbol = symbol;
				pool.lpToken.icon_url = icon_url;
			}


			const tvl = basePool.vaults[0].amount * pairState.p0 + basePool.vaults[1].amount + quotePool.vaults[1].amount * pairState.p0 + quotePool.vaults[0].amount;

			pairs.push({
				baseToken: tokenDetails,
				component,
				state: pairState,
				config,
				pools: {
					basePool,
					quotePool
				},
				tvl
			})
		}
	}

	return pairs;
}

export async function fetchPairState(pair: SelectPair) {
	const { state } = useGatewayApi;

	// Fetch pool details
	const componentDetails = await state.getEntityDetailsVaultAggregated([pair.address, pair.basePool, pair.quotePool]);

	let pairState, basePoolState, quotePoolState

	for (let component of componentDetails) {
		if (component.address === pair.address) {
			for (let field of component.details.state.fields) {

				if (field.field_name == 'state') {
					pairState = {
						p0: 0,
						shortage: 'Equilibrium',
						target_ratio: 0,
						last_outgoing: 0,
						last_out_spot: 0
					};

					for (let stateField of field.fields) {
						if (stateField.kind == 'Enum') {
							pairState[stateField.field_name as keyof typeof state] = stateField.variant_name;
						}
						else {
							pairState[stateField.field_name as keyof typeof state] = stateField.value;
						}
					}
				}
			}
		}

		if (component.address === pair.basePool) {
			basePoolState = parsePoolState(pair, component, true);
		}

		if (component.address === pair.quotePool) {
			quotePoolState = parsePoolState(pair, component, false);
		}
	}

	return { pairState, basePoolState, quotePoolState };
}

export function parsePoolState(pair: SelectPair, poolDetail: any, isBase: boolean) {


	let pool = {
		address: poolDetail.address,
		vaults: [{
			address: '',
			amount: 0
		}, {
			address: '',
			amount: 0
		}]
	};

	for (let resource of poolDetail.fungible_resources.items) {
		if (resource.resource_address == getQuoteToken(pair.dexAddress).address) {
			const index = isBase ? 1 : 0;

			pool.vaults[index] = {
				address: resource.resource_address,
				amount: parseFloat(resource.vaults.items[0].amount)
			}
		} else {
			const index = isBase ? 0 : 1;

			pool.vaults[index] = {
				address: resource.resource_address,
				amount: parseFloat(resource.vaults.items[0].amount)
			}
		}
	}

	return pool;
}

export async function fetchTVL(pairs: SelectPair[]) {
	const { state } = useGatewayApi;

	// return cache('https://radix.defiplaza.net/cache/tvl', 120, async (url: string) => {

	const prices = await fetchPrices();

	let tvl = {};

	for (let pair of pairs) {
		const componentDetails = await state.getEntityDetailsVaultAggregated([pair.basePool, pair.quotePool]);
		const baseState = parsePoolState(pair, componentDetails[0], true);
		const quoteState = parsePoolState(pair, componentDetails[1], true);

		const baseAmount = baseState.vaults[0].amount + quoteState.vaults[0].amount;
		const quoteAmount = baseState.vaults[1].amount + quoteState.vaults[1].amount;

		const basePrice = prices[baseState.vaults[0].address]?.tokenPriceUSD || 0;
		const quotePrice = prices[baseState.vaults[1].address]?.tokenPriceUSD || 0;

		tvl[pair.address] = {
			baseAmount: baseAmount,
			quoteAmount: quoteAmount,
			baseAmountUSD: baseAmount * basePrice,
			quoteAmountUSD: quoteAmount * quotePrice,
			usdAmount: baseAmount * basePrice + quoteAmount * quotePrice
		};
	}

	return tvl;
	// });
}

export async function fetchPriceFromLatestTransaction(pairAddress: string) {
	const { stream } = useGatewayApi;

	// Fetch transactions
	const result = await stream.innerClient.streamTransactions({
		streamTransactionsRequest: {
			"limit_per_page": 30,
			"kind_filter": "All",
			"affected_global_entities_filter": [pairAddress],
			"opt_ins": {
				"receipt_events": true
			},
			"order": "Desc"
		}
	});

	for (let transaction of result.items) {
		// Only include succeeded transactions
		if (transaction.transaction_status !== 'CommittedSuccess') {
			continue;
		}

		for (let event of transaction.receipt?.events) {

			// Only track SwapEvents
			if (event.name !== 'SwapEvent') {
				continue;
			}

			const componentAddress = event.emitter.entity.entity_address;

			// Only track event for our own pairs
			if (pairAddress !== componentAddress) {
				continue;
			}

			let baseAmount = 0, quoteAmount = 0

			// grab details from event
			for (let field of event.data.fields) {
				if (field.field_name == 'base_amount') {
					baseAmount = parseFloat(field.value);
				}

				if (field.field_name == 'quote_amount') {
					quoteAmount = parseFloat(field.value);
				}
			}

			return Math.abs(quoteAmount / baseAmount);
		}
	}

	return 0;
}

export async function ignitionPrice(tokenAddress: string, timestamp?: Date) {
	const { state } = useGatewayApi;

	if (!timestamp) {
		timestamp = new Date();
	}

	const result = await state.innerClient.keyValueStoreData({
		stateKeyValueStoreDataRequest: {
			at_ledger_state: {
				timestamp
			},
			key_value_store_address: IGNITION_PRICES_KV,
			keys: [
				{
					"key_json": {
						"fields": [
							{ "value": tokenAddress, "kind": "Reference" },
							{ "value": "resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd", "kind": "Reference" }],
						"kind": "Tuple"
					}
				}
			]
		}
	});

	const price = result.entries[0].value.programmatic_json.fields.find(f => f.field_name == 'price').value;

	return price;
}

export async function fetchIgnitionIncentiveAmountLeft() {
	const { state } = useGatewayApi;

	const detail = await state.getEntityDetailsVaultAggregated(IGNITION_COMPONENT_ADDRESS);

	let amounts = {
		volatile: 0,
		nonvolatile: 0,
		total: 0
	}

	for (let vault of detail.fungible_resources.items[0].vaults.items) {
		if (vault.vault_address == IGNITON_VOLATILE_VAULT) {
			amounts.volatile = +vault.amount;
			amounts.total += +vault.amount;
		}

		if (vault.vault_address == IGNITON_NONVOLATILE_VAULT) {
			amounts.nonvolatile = +vault.amount;
			amounts.total += +vault.amount;
		}
	}

	return amounts;
}